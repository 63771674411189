// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// Font path is used to include ionicons,
// roboto, and noto sans fonts
$font-path: "../assets/fonts";

// The app direction is used to include
// rtl styles in your app. For more info, please see:
// http://ionicframework.com/docs/theming/rtl-support/
$app-direction: ltr;

// Shared Variables
// --ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color-
// To customize the look and feel of this app, you can override
// the Sass variables found in Ionic's source scss files.
// To view all the possible Ionic variables, see:
// http://ionicframework.com/docs/theming/overriding-ionic-variables/

// Named Color Variables
// --ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color-
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic uses a Sass map of
// colors so you can add, rename and remove colors as needed.
// The "primary" color is the only required color in the map.

:root {
	--ion-color-primary: #14294f;
	--ion-color-third: rgb(46, 72, 119);
	--ion-color-secondary: #32db64;
	--ion-color-danger: #f53d3d;
	--ion-color-success: #32db64;
	--ion-color-medium: grey;
	--ion-color-white: #ffffff;
	--ion-color-black: #000000;
	--ion-color-grey: grey;
	--ion-color-light: #f4f4f4;
	--ion-color-dark: #222;
	--ion-color-item: #f1f3f7;
	--ion-color-hover: #e1e4ed;
	--ion-color-kioskPrimary: blue;
	--ion-color-kioskSecondary: green;
	--ion-color-appBackground: #f2f4f8;
	--ion-color-background: #f2f4f8;

	// NEW
	--ion-color-borderBox: #e0e4ed;
	--ion-color-fontBlueOne: #02123f;
	--ion-color-lightGreyText: #858b9c;
	--ion-color-ligherGreyText: #c1c8d7;
	--ion-color-warning: #ff9839;
	--ion-color-chip: #e9eeff;
	--ion-color-chip-stroke: #d5dffc;

	--ion-color-lightBorder: #ccc;
	--ion-color-backgroundField: #f4f5f6;
	--swiper-pagination-color: var(--ion-color-grey);
}

// App iOS Variables
// --ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color-
// iOS only Sass variables can go here

// App Material Design Variables
// --ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color-
// Material Design only Sass variables can go here

// App Windows Variables
// --ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color-
// Windows only Sass variables can go here

// App Theme
// --ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color-
// Ionic apps can have different themes applied, which can
// then be future customized. This import comes last
// so that the above variables are used and Ionic's
// default are overridden.

// Fonts
// --ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color---ion-color-

$font-family-base: "Inter";
$font-family-ios-base: "Inter";
$font-family-md-base: "Inter";
$font-family-wp-base: "Inter";

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100 900;
  font-optical-sizing: auto;
  src: url("../assets/fonts/Inter-VariableFont_opsz,wght.ttf") format("truetype-variations");
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 100 900;
  font-optical-sizing: auto;
  src: url("../assets/fonts/Inter-Italic-VariableFont_opsz,wght.ttf") format("truetype-variations");
}

:root {
	--ion-font-family: "Inter";
}
