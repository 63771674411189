/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "animate.css/animate.min";

@import "swiper/scss";
@import "swiper/css/effect-fade";
@import "swiper/css/effect-cube";

ion-header#impersonation-notice {
	background-color: red;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	text-align: center;
	height: 40px;

	ion-text {
		@media (max-width: 800px) {
			display: none;
		}
	}
}

body {
	min-width: 250px;
	min-height: 500px;
	background-color: var(--ion-color-appBackground);
	color: black;
}

div.tippy-content > div > div > div > button > span {
	color: black;
}

input,
ion-input,
ion-textarea,
textarea {
	caret-color: grey !important;
}

.firaSans {
	font-family: "Inter", sans-serif;
	font-weight: 700;
}

ion-header {
	ion-title {
		font-weight: 600;
	}
}

.appPage {
	height: 100%;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
}
.appVersionSpan {
	opacity: 0.7;
	margin-left: 10px;
	font-size: 11px;
}

.myAppGuests {
	height: 100%;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	margin-left: auto;
	margin-right: auto;
}

.can-go-back {
	.ionMenuButtonCls {
		display: none;
	}
}

.disable-selection {
	user-select: none;
}

@media (min-width: 800px) {
	ion-content {
		animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
		animation-duration: 1s;
	}
}

// .custom-toast {
// 	left: 50%;
// 	transform: translateX(-50%);

// 	max-width: 400px;
// 	text-align: center;
// 	font-size: 19px;
// 	font-weight: bold;
// 	color: var(--primary);
// }

.pintura-editor {
	--color-background: 0, 0, 0 !important;
	--color-foreground: 255, 255, 255 !important;
}

.myPairedAgents {
	background-color: var(--ion-color-appBackground);
	width: 100%;
	height: 100%;
	padding: 0px;
}

.myPairedLenders {
	background-color: var(--ion-color-appBackground);
	width: 100%;
	height: 100%;
	padding: 0px;
}

.myLeadsLookup {
	background-color: var(--ion-color-appBackground);

	width: 100%;
	padding: 0px;
}

.myLenderProfile {
	background-color: var(--ion-color-appBackground);
	height: 100%;
	width: 100%;
	padding: 0px;
}

.myLenderLeads {
	background-color: var(--ion-color-appBackground);
	width: 100%;
	padding: 0px;
}

.myPairedListings {
	background-color: var(--ion-color-appBackground);
	height: 100%;
	width: 100%;
	padding: 0px;
}

.myProfilePage {
	padding-left: 15px;
	padding-right: 15px;
	margin-left: auto;
	margin-right: auto;
}

.myLink {
	padding-left: 15px;
	padding-right: 15px;
	margin-left: auto;
	margin-right: auto;
}

ion-toolbar {
	color: var(--ion-color-white) !important;
	text-align: center;
}

.dividerLine {
	border: 1px solid #2e3b5f;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}

.mainHeaderIcons {
	ion-icon {
		font-size: 22px;
		margin-left: 10px;
		margin-right: 10px;
		pointer-events: all;
		cursor: pointer !important;
		color: white;
	}
}

ion-popover::part(content) {
	//	border: 1px solid #ccc;
	border-radius: 10px !important;
	overflow-x: hidden !important;
}

.select-popover {
	.item {
		//	border-width: 0px !important;
	}
}

.selectable {
	user-select: text !important;
}

app-thumbnail {
	display: block;
	top: 0;
}

.padding {
	padding: 12px;
}

.no-padding {
	padding: 0px !important;
}
///////

ion-img,
ion-button,
p,
label,
img {
	// animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
	// animation-duration: 0.8s;
}

.loginAppPage {
	padding-left: 3%;
	padding-right: 3%;
	padding-top: 5%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	max-width: 800px;
}

@media (min-width: 1200px) {
	.appPage {
		padding-left: 5%;
		padding-right: 5%;
		margin-left: auto;
		margin-right: auto;
	}
	.myAppGuests {
		height: 100%;
		width: 100%;
		padding-left: 10px;
		padding-right: 10px;
		margin-left: auto;
		margin-right: auto;
	}

	.loginAppPage {
		margin-left: auto;
		margin-right: auto;
		max-width: 800px;
	}
	.myProfilePage {
		padding-left: 10%;
		padding-right: 10%;
		margin-left: auto;
		margin-right: auto;
	}
}

// new lender css
.blockBox {
	background: var(--ion-color-white);
	border-radius: 6px;
}

.slim-button {
	--padding-start: 0.2rem; /* Adjust as needed */
	--padding-end: 0.2rem; /* Adjust as needed */
}

.mainContentBox {
	width: 95%;
	max-width: 800px;
	margin-top: 20px;

	padding-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
}

.mainContentBoxFull {
	width: 100%;
	margin-top: 20px;
	padding-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
}

.borderBox {
	border: 1px solid;
	border-color: var(--ion-color-borderBox);
	border-radius: 6px;
}

.borderTopOnly {
	border-top: 1px solid;
	border-color: var(--ion-color-borderBox);
}

.borderDivider {
	margin-left: 20px;
	margin-right: 20px;
	border-bottom: 1px solid;
	border-color: var(--ion-color-borderBox);
}

.mediumHeaderText {
	color: var(--ion-color-fontBlueOne);
	font-size: 16px;
	font-weight: 700;
	font-family: Inter;
}

.largeHeaderText {
	color: var(--ion-color-fontBlueOne);
	font-size: 22px;
	font-family: Inter;
}

.smallLightText {
	font-family: Inter;
	color: #858b9c;
	font-size: 12px;

	b {
		color: var(--ion-color-fontBlueOne);
	}
}

.smallText {
	font-size: 13px;
}

.lightText {
	color: var(--ion-color-grey);
}

.blueFont {
	color: var(--ion-color-fontBlueOne);
}

.standardLabel {
	font-family: Inter;
	font-weight: 700;
	font-size: 14px;
	color: var(--ion-color-fontBlueOne);
	user-select: text !important;
}

.centerBox {
	margin-left: auto;
	margin-right: auto;
}
// end lender css

.largeButton {
	font-size: 18px;
}

button,
.button {
	text-decoration: none;
	text-transform: none;
	border-radius: 6px !important;
	font-family: "Inter", sans-serif;
	font-weight: 700;
	//	min-width: 100px;
}

ion-toolbar,
.toolbar {
	button,
	.button {
		font-weight: 700;
		min-width: auto;
	}
}

.alert-button {
	font-weight: 700 !important;
}

.iconSize {
	font-size: 24px !important;
}

.wrap-text {
	overflow: hidden;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.width-40 {
	width: 60px;
}

.bold {
	font-weight: bold !important;
}
.italic {
	font-style: italic;
}
.normal {
	font-weight: normal !important;
}

.urlLinkHeader {
	color: var(--ion-color-dark) !important;
}

.leftAlign {
	text-align: left;
}

.rightAlign {
	text-align: right;
}

.inline-block {
	display: inline-block;
}
.smallMenuLogo {
	float: right;
}
.smallMenuLogo img {
	width: 40px;
}

.floatRight {
	float: right;
}

.full-height {
	height: 100%;
}

.full-width {
	width: 100%;
}

.fullWidth {
	width: 100%;
}

button[ion-button] {
	border-radius: 6px;
}

ion-chip {
	--color: var(--ion-color-fontBlueOne);
	--background: var(--ion-color-chip);
	--border-color: var(--ion-color-chip-stroke);
	--border: var(--ion-color-chip-stroke);

	ion-label {
		font-weight: 400;
	}
}

.menu-user {
	color: #ffffff;
	font-weight: 600;
	text-align: center;
	font-size: 18px;
}

.color-dark {
	color: var(--ion-color-dark);
}

.color-primary {
	color: var(--ion-color-primary);
}

.df-align-center {
	display: flex;
	align-items: center;
}

.urlLink {
	color: var(--ion-color-primary);
}

.df-justify-center {
	display: flex;
	justify-content: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	color: #000;
	font-weight: 600;
	font-family: "Inter", "-apple-system", "Helvetica Neue", "Roboto", "Segoe UI", sans-serif;
	line-height: 1.2;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
	margin-top: 10px;
	margin-bottom: 5px;
}

h4,
.h4 {
	margin: 0;
	font-size: 18px;
}

h3,
.h3 {
	font-size: 24px;
}

h2,
.h2 {
	font-size: 26px;
}

.hide {
	display: none !important;
}

.position-relative {
	position: relative;
}

.highlightable {
	-webkit-user-select: text !important;
	-moz-user-select: text !important;
	user-select: text !important;
}

.side-menu-gradient {
	min-height: 400px;
	background-color: var(--ion-color-light);
	// background: -webkit-linear-gradient(left top,
	//   $SIDEMENU_TOP,
	//   $SIDEMENU_BOTTOM);
	// background: -o-linear-gradient(bottom right, $SIDEMENU_TOP, $SIDEMENU_BOTTOM);
	// background: -moz-linear-gradient(bottom right,
	//   $SIDEMENU_TOP,
	//   $SIDEMENU_BOTTOM);
	// background: linear-gradient(to bottom right, $SIDEMENU_TOP, $SIDEMENU_BOTTOM);
}

ion-reorder-group {
	ion-item {
		--padding-bottom: 10px;
	}
}

app-property-layout {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	font-size: 15px;
	$items: 1;
	$animation-time: 10s;
	$transition-time: 0.5s;
	$scale: 20%;

	$total-time: ($animation-time * $items);
	$scale-base-1: (1 + $scale / 100%);
	@keyframes kenburns {
		0% {
			// background-position: -30px -30px;
			transform: translatex(-30px) translatey(-30px) scale(1.2);
		}
		100% {
			// background-position: 30px 30px;
			transform: translatex(30px) translatey(30px) scale(1.2);
		}
	}
}

.alertQRPopup {
	text-align: center;
	min-width: 450px;
	width: 100%;
	min-height: 500px;

	div {
		img {
			width: 100px;
			height: 100px;
			margin-top: 20px;
		}
	}
}

.center-form {
	label {
		background-color: rgba(255, 255, 255, 0.7);
		border-color: rgba(255, 255, 255, 0);

		&.top-input {
			border-radius: 4px;
		}

		&.bottom-input {
			border-radius: 4px;
		}
	}
}

.clear-custome-input {
	border: none;
	background: no-repeat;
	outline: none;
	width: 100%;
	height: 35px;
}

.flex-container {
	display: flex;
	align-items: center; /* Align items vertically */
}

.item-input {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	align-items: center;
	position: relative;
	overflow: hidden;
	padding: 6px 16px 6px 16px;
}

.floating-label {
	padding-bottom: 10px;
}
.input-label {
	display: table;
	padding: 7px 10px 7px 0px;
	max-width: 270px;
	min-width: 160px;
	font-size: 16px;
}

.border-bottom {
	border-bottom: 1px solid #ddd !important;
}

// AUTOFILL CSS
input:-webkit-autofill,
input:-webkit-autofill:focus {
	box-shadow: 0 0 0 1000px white inset;
	-webkit-text-fill-color: #333;
}

.toolbar {
	text-align: center;
}

.toolbar-title {
	font-weight: 700;
	color: var(--ion-color-light);
}

.toolbar-background {
	background-color: var(--ion-color-primary);
	border: none;
}

ion-item.checkbox-form-wrapper > ion-checkbox > div {
	position: relative !important;
	width: 28px !important;
	height: 28px !important;
	margin: auto;
	display: block !important;
	border: 1px solid var(--ion-color-grey) !important;
	background-color: var(--ion-color-white) !important;
	cursor: pointer;
	-webkit-appearance: none;
	border-radius: 50px !important;

	&.checkbox-checked {
		background-color: var(--ion-color-fontBlueOne) !important;
		border: 0 !important;
	}
}

ion-item > ion-checkbox > div > div.checkbox-inner {
	left: 10px !important;
	top: 4px !important;
	position: absolute;
	width: 8px !important;
	height: 14px !important;
	border-width: 1px !important;
	border-color: #ffffff !important;
	border-top: 0 !important;
	border-left: 0 !important;
}

.item-inner {
	color: #444;
}

.item {
	color: #444;
}

.content {
	color: #444;
}

.side-bar-custome {
	background-color: var(--ion-color-primary) !important;

	&__item {
		margin-left: 10px;

		.button-inner {
			font-size: 16px;
			text-align: left;
		}

		ion-icon {
			margin-left: 10px;
			min-width: 18px;
			font-size: 20px;
			color: var(--ion-color-white);
		}
	}
	.btnMenu.button-ios {
		height: auto;
		min-height: 2.7em;
		.button-inner {
			line-height: 20px;
		}

		@media (max-width: 320px) {
			min-height: 2em;
		}
	}
	.btnMenu.button-md {
		height: auto;
		min-height: 3.6rem;
		.button-inner {
			line-height: 20px;
		}
	}
}

.list .item:first-child,
.list-inset .item:first-child,
.padding > .list .item:first-child {
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}

.card .item,
.list-inset .item,
.padding > .list .item,
.padding-horizontal > .list .item {
	margin-right: 0;
	margin-left: 0;
}

.item,
.item h1,
.item h2,
.item h3,
.item h4,
.item h5,
.item h6,
.item p,
.item-content,
.item-content h1,
.item-content h2,
.item-content h3,
.item-content h4,
.item-content h5,
.item-content h6,
.item-content p {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.item {
	border-color: #ddd;
	color: #444;
	position: relative;
	z-index: 2;
	margin: -1px;
	border-width: 1px;
	border-style: solid;
	font-size: 16px;
}

.versionNum {
	position: absolute;
	bottom: 5px;
	padding-left: 10px;
	padding-bottom: 5px;
	color: white;
	opacity: 0.3;
	font-size: 10px;
}

// Global styling

ion-header ion-icon.back-button-icon.icon {
	color: var(--ion-color-white);
}

ion-header span.back-button-text.back-button-text-ios {
	color: var(--ion-color-white);
}

ion-icon {
	margin-right: 3px;
}

.modal-close {
	margin-left: 10px;
	font-size: 22px;
}

.modal-done {
	color: white;
	font-size: 18px;
}

.modal-share {
	margin-right: 10px;
	font-size: 23px;
	color: #14294f;
}

.invalid {
	border-bottom: 1px solid #ea6153 !important;
}

.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}

.spacer-5 {
	height: 5px;
}

.spacer-10 {
	height: 10px;
}

.spacer-15 {
	height: 15px;
}
.spacer-20 {
	height: 20px;
}

.spacer-17 {
	height: 17px;
}
.spacer-25 {
	height: 25px;
}

.spacer-26 {
	height: 26px;
}

.spacer-28 {
	height: 28px;
}

.spacer-30 {
	height: 30px;
}

.spacer-32 {
	height: 32px;
}

.spacer-36 {
	height: 36px;
}

.spacer-50 {
	height: 50px;
}

.spacer-52 {
	height: 52px;
}

.spacer-56 {
	height: 56px;
}

.spacer-69 {
	height: 69px;
}

.spacer-100 {
	height: 100px;
}

.inline-flexbox {
	display: inline-flexbox;
}
.inline-flex {
	display: inline-flex;
}
.flex {
	display: flex;
}

@font-face {
	font-family: "Montserrat";
	src: url(assets/fonts/Montserrat-Bold.ttf);
}

.font-montserrat {
	font-weight: bold;
	font-family: "Montserrat";
}

// @media (min-width: 0px) {
// 	.modal-backdrop-bg {
// 		opacity: 0.5 !important;
// 		background-color: #000;
// 	}
// }

/*
@media only screen and (orientation: portrait) {
  .address-modal {
    ion-modal::part(content) {
      height: 400px !important;
      top: calc(50% - (400px / 2));
    }
  }
}
*/

.swiper {
	color: #fff;
}
.swiper-pagination-bullet-active {
	opacity: 1;
	background: #fff !important;
	bottom: 5px;
	height: 8px;
	width: 8px;
	display: inline-block;
	margin-inline: 4px;
	border-radius: 50%;
	border: 1px solid #fff;
}

.swiper-pagination-bullet {
	opacity: 0.9;
	background: #bbb;
	bottom: 5px;
	height: 8px;
	width: 8px;
	display: inline-block;
	margin-inline: 4px;
	border-radius: 50%;
	border: 1px solid #bbb;
}

.swiper-pagination {
	position: absolute;
	bottom: 10px;
	left: 47%;
}

ion-modal {
	--border-radius: 10px !important;

	ion-header {
		margin-bottom: 10px;
	}

	.toolbarButton {
		height: 60px;
		font-size: 18px;
		margin-right: 5px;
		min-width: 50px;
	}
}

ion-app.orientation-portait {
	ion-modal::part(backdrop) {
		visibility: visible !important;
	}
	ion-modal::part(content) {
		border-radius: 10px;
		--border-radius: 10px !important;
		overflow: hidden !important;
		-webkit-box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
		box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
		height: calc(var(--vh, 1vh) * 80) !important;
		top: calc(10 * var(--vh, 1vh)) !important;
		height: 650px;
		// height: 600px !important;
		// top: calc(50% - (600px/2)) !important;
		left: calc(50% - (600px / 2)) !important;
		position: absolute !important;
		width: 600px !important;

		@media (max-width: 651px) {
			width: 100% !important;
			height: 100% !important;
			left: calc(50% - 100% / 2) !important;
			top: calc(var(--vh, 1vh)) !important;
		}
	}
	.edit-input {
		ion-modal::part(content) {
			// height: 250px !important;
			// top: calc(50% - (250px/2)) !important;
		}
	}
}

.my-custom-popover {
	--min-width: fit-content;
	--pading-bottom: 12px;
	--box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3) !important;
	//	-webkit-box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
	//	box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
}

.custom-modal-css ion-modal::part(content) {
	min-height: 600px !important;
}

ion-input,
ion-textarea {
	--padding-start: 10px !important;
	--padding-end: 5px !important;
	--border-radius: 10px;
}

// *** Custom segments ***
.segmentSectionOverflow {
	overflow-x: auto;
	padding-bottom: 5px;

	&__centred {
		width: 99%;
		text-align: center;
	}

	.segmentSection {
		width: 100%;
		//	min-width: 440px;
		max-width: 650px;
		margin-left: auto;
		margin-right: auto;
		border-bottom: 1px solid;
		border-color: var(--ion-color-borderBox);
		//	cursor: pointer !important;
		border-radius: 0px !important;

		button {
			margin-top: 20px;
			background: none;
			outline: none;
			border-radius: 0px !important;
		}

		.segmentButton {
			//	min-width: 120px;
			height: 30px;
			width: 50%;
			padding-left: 10px;
			padding-right: 10px;
			// padding-top: 20px;
			display: inline-block;
			font-family: Inter;
			font-weight: 400;
			font-style: normal;
			font-size: 16px;
			line-height: 22px;
			/* identical to box height */
			text-align: center;
			color: #858b9c;
		}

		.enabledSegment {
			color: #02123f;
			font-weight: 700;
			font-size: 15px;
			padding-bottom: 22px;
			border-bottom: 2px solid #02123f;
		}
	}
}
// *** Custom segments ***

// @media (min-height: 600px) and (max-width: 660px) and (orientation: portrait) {
// 	ion-modal::part(content) {
// 		width: 90% !important;
// 		left: calc(50% - 90% / 2) !important;
// 	}
// }

ion-app.orientation-landscape {
	ion-modal {
		ion-modal::part(content) {
			border-radius: 10px;
			--border-radius: 10px !important;
			overflow: hidden !important;
			-webkit-box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
			box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
			height: calc(var(--vh, 1vh) * 90) !important;
			top: calc(9 * var(--vh, 1vh)) !important;
			max-height: 640px;
			// height: 600px !important;
			// top: calc(50% - (600px/2)) !important;
			left: calc(50% - (600px / 2)) !important;
			position: absolute !important;
			width: 600px !important;
			@media (max-width: 651px) {
				width: 90% !important;
				left: calc(50% - 90% / 2) !important;
			}
		}
	}
}

.input-cover {
	display: none !important;
}

.subTitle {
	color: #162850;
}
.font-10 {
	font-size: 10px;
}
.font-12 {
	font-size: 12px;
}
.font-14 {
	font-size: 14px;
}
.font-20 {
	font-size: 20px;
}
.pad-5 {
	padding: 5px;
}
.pad-10 {
	padding: 10px;
}
.pad-20 {
	padding: 20px;
}
.pad-top-5 {
	padding-top: 5px;
}
.pad-top-10 {
	padding-top: 10px;
}
.pad-top-15 {
	padding-top: 15px;
}
.pad-top-20 {
	padding-top: 20px;
}
.pad-top-50 {
	padding-top: 50px !important;
}
.pad-top-60 {
	padding-top: 60px !important;
}
.pad-bottom-10 {
	padding-bottom: 10px;
}
.padding-20 {
	padding: 20px;
}
.pad-left-5 {
	padding-left: 5px;
}
.pad-left-10 {
	padding-left: 10px;
}
.pad-left-15 {
	padding-left: 15px;
}
.pad-right-5 {
	padding-right: 5px;
}
.pad-right-10 {
	padding-right: 10px;
}
.margin-left-5 {
	margin-left: 5px;
}
.margin-left-10 {
	margin-left: 10px;
}
.margin-left-15 {
	margin-left: 15px;
}
.margin-left-20 {
	margin-left: 20px !important;
}
.margin-top-2 {
	margin-top: 2px;
}
.margin-top-10 {
	margin-top: 10px;
}
.margin-top-15 {
	margin-top: 15px;
}
.margin-auto {
	margin: auto;
}

.line-height-35 {
	line-height: 35px;
}

a {
	cursor: pointer;
}
.link {
	cursor: pointer !important;
}
.underline {
	text-decoration: underline;
}

.rightAlign {
	text-align: end;
}
.centerAlign {
	text-align: center;
}
.cleanText {
	text-decoration: none !important;
}

.faded {
	opacity: 0.3;
}

.disableClicks {
	pointer-events: none !important;
}

.enableClicks {
	pointer-events: all;
}

.slotButtonIcon {
	font-size: 16px;
}

.vertical-align {
	vertical-align: middle;
}

/* FIX FOR ION SELECT SCROLLABLE LIST */
.sc-ion-select-popover-h,
ion-list.sc-ion-select-popover {
	overflow-y: auto !important;
	overflow-x: hidden !important;
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
}

ion-select {
	--placeholder-opacity: 0.7 !important;
}

.dialog-modal ion-modal::part(content) {
	@media (min-width: 652px) {
		max-height: 520px;
	}
	@media (max-height: 599.99px) and (orientation: landscape) {
		height: 90%;
	}
	@media (min-height: 600px) and (max-height: 750px) and (orientation: landscape) {
		height: 80%;
	}
}

.buttonIcon {
	ion-button {
		--padding-end: 4px !important;
		--padding-start: 4px !important;
		margin-top: 0px;
	}
}

.noTooltip {
	pointer-events: none;
}

.grey-message-area {
	background-color: #f2f4f8;
	color: #02123f;
	font-size: 12px;
	padding: 5px;
	border-radius: 5px;
	width: fit-content;
	display: inline-flex;
}

.slide-question {
	&__multiple-choice-answers {
		.item-radio-checked {
			border: 2px solid var(--primary) !important;
			ion-label {
				color: var(--primary) !important;
				font-weight: bold;
			}
		}
	}
}

ion-button {
	--box-shadow: none;
	border-radius: 6px;
}

.inline-grid {
	display: inline-grid; /* Set display to inline-grid */
}

.verticalAlign {
	display: flex; /* Set display to flex */
	align-items: center; /* Vertically align items to the center */
}

.overflow-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 99%; /* Optionally set a max-width to prevent the ellipsis from appearing too early */
}

.slim-search-box {
	background-color: #ffffff00;
	color: #fff;
	border-bottom: 2px solid #fff;
}

// Country picker styles

.country-picker-selector::part(content) {
	@media (max-width: 600px) {
		margin-left: 5px;
	}

	@media (min-width: 600px) {
		margin-left: 115px;
	}

	margin-top: 5px;
	width: fit-content;
}

// Width the arrow icon
.country-picker-selector::part(arrow)::after {
	display: none !important;
}

// Make the square border match the country picker borders
.input-borders .input-wrapper {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

// Country picker styles

.editFieldLabel {
	display: block !important;

	font-size: 16px !important;
	font-weight: bold;

	padding-left: 5px;
	margin: 15px;
}

ion-alert {
	line-height: 24px;
}
